// import { Link } from "gatsby"
import React from 'react'
import logoHispanicFederation from '../images/logo-hispanic-federation.png'
import logoAmericanExpress from '../images/logo-american-express.png'

class About extends React.Component {
  render() {
    return (
      <section className="About">
        <h1>Meet Our Partners</h1>
        <div className="wrapper">
          <div className="charity">
              <a
                href="https://hispanicfederation.org/resources/hf_emergency_assistance_fund_for_latino_nonprofits/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoHispanicFederation} alt="Hispanic Federation logo" />
              </a>
            <p>
              Hispanic Federation and the Miranda Family announce the creation of the Hispanic Federation Emergency Assistance Fund to help offset the costs of the COVID-19 pandemic in immigrant and Black communities. The goal of the fund is to ensure that immigrant-focused community-based nonprofits are able to continue to serve participants and their local communities, are equipped to look after the health and safety of their staff and can pay for basic operating costs in this time of deep crisis and uncertainty.
            </p>
          </div>
          <div className="charity">
            <a
              href="https://www.americanexpress.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logoAmericanExpress} alt="American Express logo" />
            </a>
            <p>Proud Supporter of the Hispanic Federation Emergency Assistance Fund.</p>
          </div>
        </div>
      </section>
    )
  }
}

export default About
