import React from 'react'

const Logo = ({
    circleFill = "#FFF",
    triangleFill = "#FFF",
    className = "",
  }) => (
    
    <svg 
    className={`svg-icon ${className || ""}`}
    width="128px" height="128px" viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg" href="http://www.w3.org/1999/xlink">
    <title>Group 8</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-8" fill={circleFill} fill-rule="nonzero">
            <path d="M102.389333,89.8323779 L72.1946665,39 L42,89.8323779 L102.389333,89.8323779 Z M72.1946665,54.6643779 L88.3326665,81.8323779 L56.0556665,81.8323779 L72.1946665,54.6643779 Z" id="Polygon" transform="translate(72.194667, 64.416189) rotate(-270.000000) translate(-72.194667, -64.416189) " fill={triangleFill}></path>
            <path d="M64,0 C28.653776,0 0,28.653776 0,64 C0,99.346224 28.653776,128 64,128 C99.346224,128 128,99.346224 128,64 C128,28.653776 99.346224,0 64,0 Z M64,8 C94.927946,8 120,33.072054 120,64 C120,94.927946 94.927946,120 64,120 C33.072054,120 8,94.927946 8,64 C8,33.072054 33.072054,8 64,8 Z" id="Oval" fill={circleFill}></path>
        </g>
    </g>
</svg>
    )


export default Logo
