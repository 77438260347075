/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import VideoButton from "./VideoButton"
import Header from "./Header"
import Card from './Card'
import Footer from './Footer'
import About from './About'
import Family from './Family'
import Logo from './Logo'
import '../style/sass/style.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteDataQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulPrizeoCampaign {
        edges {
          node {
            url
            title
            image {
              id
              file {
                url
              }
            }
            id
          }
        }
      }
      allContentfulCharitybuzzAuction {
        edges {
          node {
            url
            title
            image {
              id
              file {
                url
              }
            }
            id
          }
        }
      }
      allContentfulWelcomeMessage {
        edges {
          node {
            activated
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <VideoButton />
      <main className="main-content" id="main-content">
      {/* <Image /> */}
        {children}
          <Logo className="Logo Logo--background" />
        <section className="prizeo">
            {data.allContentfulWelcomeMessage.edges.map(edge => (
                edge.node.activated ? <h2 className="welcome-message">Check back often for more amazing prizes and experiences!</h2> : null
            ))}
          <h1>Prizeo Sweepstakes</h1>
          <p>
            At Prizeo, giving small means winning big!
            <strong> Donate as little as $10</strong> to enter for the chance
            to win an unforgettable experience.
          </p>
          <div className="card-container">
            {data.allContentfulPrizeoCampaign.edges.map(edge => (
              <Card
                key={edge.node.id}
                title={edge.node.title}
                link={edge.node.url}
                imageURL={edge.node.image.file.url}
                CTA="Enter to Win"
              />
            ))}
          </div>
          {/* <a href="#" className="button secondary">
            Explore More Grand Prizes
          </a> */}
        </section>
        <section className="charitybuzz">
          <h1>Charitybuzz Auctions</h1>
          <p>
            At Charitybuzz, <strong>bid to win any of these exciting experiences</strong> and
            make a real-world impact in the lives of others.
          </p>
          <div className="card-container">
            {data.allContentfulCharitybuzzAuction.edges.map(edge => (
              <Card
                key={edge.node.id}
                title={edge.node.title}
                link={edge.node.url}
                imageURL={edge.node.image.file.url}
                CTA="Bid to Win"
              />
            ))}
          </div>
          <a
            href="https://charitybuzz.com/raiseup"
            className="button secondary"
            target="_blank"
            rel="noreferrer"
          >
            Explore More Auctions
          </a>
        </section>
        <Family />
        <About />
        <Footer />
        {/* <DirectDonate /> */}
        {/* <div className="copyright">
          © {new Date().getFullYear()}
          {` `}
          <a href="https://www.charitynetwork.com">Charity Network</a>
        </div> */}
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
