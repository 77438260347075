import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Logo from './Logo'
import linPhoto from '../images/image-lin-manuel-miranda.png'

class Header extends React.Component {
  render() {
    return (
      <>
      <div className="Header">
        <Logo className="Logo Logo--hero" />
        <div className="Header__wrapper wrapper">
          <div className="Header__grid">
            <div className="Header__text">
              <h1>Raise Up</h1>
              <div className="Header__hashtags"><Logo className="small-logo" palmFill="#fff" thumbFill="#fff" indexFill="#fff" middleFill="#fff" ringFill="#fff" pinkieFill="#fff"/>Raise Up &nbsp; &nbsp;  #RaiseUp</div>
              <p className="Header__intro"><strong>Lin-Manuel Miranda, his family, and some special friends</strong> have joined with Prizeo and Charitybuzz to create a collection of auctions + sweepstakes to benefit the Hispanic Federation in their efforts <strong>to raise essential funds for immigrant and Black communities impacted by COVID-19.</strong></p>
              {/* <a className="button" href="#main-content">Donate Now</a> */}
              <AnchorLink className="stripped button" to="#main-content" title="Donate Now" />
            </div>
          </div>
          <img className="Header__hero" src={linPhoto} alt="Lin-Manuel Miranda" />
        </div>
      </div>
      </>
    )
  }
}

export default Header
