// import { Link } from "gatsby"
import React from 'react'
import { Link } from "gatsby-plugin-modal-routing"
import IconPlay from './IconPlay'
import ImageScreenGrabLinVanessa from './ImageScreenGrabLinVanessa'
import ImageScreenGrabSiblings from './ImageScreenGrabSiblings'
import ImageScreenGrabParents from './ImageScreenGrabParents'

const Video = () => (
  <>
  <Link className="Video Video__video-button" to="/watch-video/" asModal>

    <div className="Video__screen-grab Video__screen-grab--one">
      <ImageScreenGrabLinVanessa />
    </div> 
    <div className="Video__screen-grab Video__screen-grab--two">
      <ImageScreenGrabParents />
    </div> 
    <div className="Video__screen-grab Video__screen-grab--three">
      <ImageScreenGrabSiblings />
    </div> 
    <div className="Video__message">
      <IconPlay className="Video__icon" />
      <h3>A message from the Miranda Family</h3>
    </div>
  </Link>
  </>
)

export default Video
