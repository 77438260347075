// import { Link } from "gatsby"
import React from 'react'
import Video from './Video'
import ImageMirandaFamily from './ImageMirandaFamily'

import ImageMirandaSignature from '../images/image-mirandas-signature.svg'

class Family extends React.Component {
  render() {
    return (
      <section className="Family">
        <div className="Family__wrapper">
          <div className="Family__letter">
            <p>The COVID-19 pandemic continues to leave a devastating impact across the United States, especially for our vulnerable immigrant, Black, and undocumented communities. These communities are often left in the margins, from being forced to continue working and putting themselves directly in harm's way, to being the first to be laid off and left uninsured, to being detained in unsanitary, overcrowded detention centers and facing deportation in the midst of a global health crisis.</p>
            <p>To support this high-risk population, our family is teaming up with some of Lin-Manuel's closest friends and colleagues to raise money for community-based organizations serving immigrant and Black communities during this uncertain time through the Hispanic Federation's Emergency Assistance Fund. Check out an array of exclusive experiences and memorabilia below that you can win to support immigrants throughout the nation - your $10 entries can make a difference.</p>
              <p>Thank you for your support.</p>
              <p>Siempre,</p>
              <img src={ImageMirandaSignature} alt=""/>
          </div>
          <div className="Family__photo">
            <ImageMirandaFamily />
              <Video />
          </div>
        </div>
      </section>
    )
  }
}

export default Family
