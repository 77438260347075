// import { Link } from "gatsby"
import React from 'react'

const Video = ({
  width = "400",
  height = "400",
}) => (

  <div className="aspect-ratio-wrapper">
    <iframe src="https://player.vimeo.com/video/414926527" width={width} height={height} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>

)
export default Video