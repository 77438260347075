// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from 'react'

const Logo = ({
    style = {},
    palmFill = "#009ADB",
    middleFill = "#7D6BAF",
    indexFill = "#5765AE",
    thumbFill = "#1A68B2",
    ringFill = "#0096BA",
    pinkieFill = "#00B8F0",
    width = "100%",
    className = "",
    viewBox = "0 0 32 32"
  }) => (
    
      <svg width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink" width="421px" height="449px" viewBox="0 0 421 449" version="1.1" xmlns="http://www.w3.org/2000/svg" href="http://www.w3.org/1999/xlink">
          <title>logo-main</title>
          <desc>Created with Sketch.</desc>
          <defs>
              <polygon id="path-1" points="0.884685197 0.110598288 269.28334 0.110598288 269.28334 213 0.884685197 213"></polygon>
              <polygon id="path-3" points="0 0.721877841 84.8783234 0.721877841 84.8783234 100.751145 0 100.751145"></polygon>
              <polygon id="path-5" points="0.683734636 0.288702255 80.574 0.288702255 80.574 128.579417 0.683734636 128.579417"></polygon>
          </defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="logo-main">
                  <g id="Group-3" transform="translate(100.000000, 235.588000)">
                      <mask id="mask-2" fill="white">
                          <use href="#path-1"></use>
                      </mask>
                      <g id="Clip-2"></g>
                      <path d="M203.4944,8.2153 C176.1274,6.0883 151.2084,20.6383 137.4004,43.6953 C126.6004,19.0863 103.7084,1.5193 76.2904,0.1893 C37.8324,-1.6757 4.2874,29.8303 1.1304,70.7843 C-0.8086,95.9183 8.8654,119.0183 25.2974,134.2553 L97.3484,200.2563 C114.2254,215.7173 138.7694,217.2663 157.4574,204.0493 L237.2324,147.6273 C255.4484,134.5773 267.9494,112.8753 269.1854,87.6973 C271.2004,46.6713 241.8824,11.1993 203.4944,8.2153" id="Fill-1" fill={palmFill} mask="url(#mask-2)"></path>
                  </g>
                  <g id="Group-6" transform="translate(0.000000, 227.588000)">
                      <mask id="mask-4" fill="white">
                          <use href="#path-3"></use>
                      </mask>
                      <g id="Clip-5"></g>
                      <path d="M74.0569,53.2617 C69.2839,43.9717 67.6019,32.6587 61.6069,24.2337 C53.7899,13.2477 44.4009,4.4527 30.7409,1.3247 C18.6639,-1.4413 10.5139,5.7577 4.3859,13.8167 C-1.7391,21.8717 -1.4141,31.4377 5.1419,40.4217 C14.2239,52.8647 26.9459,61.9487 38.9869,69.9257 C52.1499,78.6457 64.0259,88.4577 75.0679,99.1237 C78.9939,102.9157 85.7319,99.5877 84.7889,94.2127 C81.4049,74.9057 77.1259,59.2347 74.0569,53.2617" id="Fill-4" fill={thumbFill} mask="url(#mask-4)"></path>
                  </g>
                  <path d="M238.5891,91.3099 C238.0771,108.4329 236.5991,132.8329 236.9861,157.1789 C237.1431,167.0169 235.0811,175.1229 231.1561,183.1869 C227.0781,191.5659 223.9931,198.5409 215.4151,198.2219 C206.8671,197.9039 201.1411,187.7839 198.6181,180.6589 C197.3001,176.9349 196.0701,172.8829 195.9581,168.9259 C194.9171,132.1619 187.7961,96.1919 185.1581,59.8679 C183.7561,40.5549 184.2321,21.5979 197.9981,6.7129 C202.1501,2.2249 210.4101,-1.7171 215.3221,0.7729 C227.6501,7.0229 234.1841,18.6209 235.6741,35.2469 C237.1221,51.3959 237.4201,67.6879 238.5891,91.3099" id="Fill-7" fill={middleFill}></path>
                  <path d="M87.7483,86.5791 C87.9873,63.8991 92.2283,39.8631 110.8333,42.6951 C116.0313,43.4861 134.1133,54.6391 134.1683,60.1431 C134.4503,88.8451 150.3623,113.6571 154.0953,141.5471 C155.1463,149.4061 154.4573,157.8511 157.6793,165.3631 C158.7773,167.9241 158.6013,170.8951 156.9483,173.1391 C151.5273,180.5001 161.6433,193.2751 156.0403,201.1071 C151.6263,207.2781 145.6173,208.9741 138.2503,209.3641 C131.6603,209.7141 128.0953,205.0131 126.0823,199.4411 C113.9163,165.7481 101.7953,132.0381 89.9283,98.2401 C88.2413,93.4361 88.0203,88.1171 87.7483,86.5791" id="Fill-9" fill={indexFill}></path>
                  <path d="M308.6858,43.8331 C329.5838,45.8571 340.4558,65.6991 334.7898,89.6641 C331.2558,104.6131 327.0328,119.3981 323.2148,134.2231 C320.7738,143.7041 312.3568,151.2641 311.0728,162.6681 C310.3578,169.0081 310.5898,174.9051 309.4958,181.6671 C307.4558,194.2661 301.0078,208.1011 290.7068,210.7421 C281.1348,213.1961 272.8278,205.9601 272.2768,193.9841 C271.8398,184.4971 272.9478,174.9631 275.7428,165.9011 C282.2238,144.8891 283.1858,128.4831 279.5498,107.5961 C276.3808,89.3921 285.9888,69.7971 291.2498,54.2781 C295.0228,43.1551 301.5498,43.7671 308.6858,43.8331" id="Fill-11" fill={ringFill}></path>
                  <g id="Group-15" transform="translate(340.000000, 109.588000)">
                      <mask id="mask-6" fill="white">
                          <use href="#path-5"></use>
                      </mask>
                      <g id="Clip-14"></g>
                      <path d="M3.3411,100.3083 C10.4541,71.5483 18.8291,46.0593 38.7851,22.5103 C41.8381,18.9073 42.8051,11.0453 48.0141,8.1833 C55.9781,3.8073 58.7361,-4.1997 68.3391,3.4993 C77.9881,11.2353 82.0551,20.6783 80.0941,33.1613 C77.1971,51.5983 65.7751,65.1423 54.5281,78.2653 C41.6331,93.3113 30.1951,109.7333 15.6121,123.2353 C13.5391,125.1553 12.2621,128.9223 9.2241,128.5543 C4.9671,128.0383 2.6041,124.5683 1.5291,121.6563 C-0.8489,115.2093 2.4531,107.2633 3.3411,100.3083" id="Fill-13" fill={pinkieFill} mask="url(#mask-6)"></path>
                  </g>
              </g>
          </g>
      </svg>
    )


export default Logo

